import moment from "moment"


export const setNewProduct = (data) => {
  let d = new Date().getTime()
  return {
    id: d,
    name: data,
    status: 'pending'
  }
}

export const getFullNameByName = (nickname, users) => {
  let res = users.find(function (el) {
    return el.name === nickname;
  });

  // Если объект найден, возвращаем имя и фамилию, иначе возвращаем сообщение об отсутствии
  if (res) {
    return `${res.surname} ${res.name}`;
  } else {
    return "Person not found";
  }
}

export const getCurrentWeek = () => { ///header
  return moment().isoWeekday(1).week()
}

export const getTimestampFromInputValue = (value) => {
  return moment(value).unix()
}

export const getDateFromTimestamp = (value) => {
  return moment(value*1000).format('DD.MM.YY')
}

export const getDateInputValueFromTimestamp = (value) => {
  let t = moment(value * 1000)
  return `${t.format('YYYY-MM-DD')}`
}

export const getTimestampFromdayAndWeek = (day, week) => {
  const startOfYear = moment().isoWeek(1).startOf('year');
  const currentDayOfWeek = startOfYear.day();
  const daysToAdd = (currentDayOfWeek < 1) ? 1 - currentDayOfWeek : 8 - currentDayOfWeek;
  const firstMondayOfYear = startOfYear.clone().add(daysToAdd, 'days');
  const addWeeks = firstMondayOfYear.add(week - 2, 'weeks')
  const addDays = addWeeks.add(day, 'days')
  const timestamp = addDays.valueOf() / 1000;
  return timestamp
}

export const getCurrentDay = () => {
  let time = moment().isoWeekday()
  return time - 1
}

export const getCurrentYear = () => {
  let time = moment().year()
  return time
}
export const getDate = (weekNumber, dayNumberOfWeek) => {
  var result = moment().isoWeek(+weekNumber).isoWeekday(+dayNumberOfWeek + 1).format("D.MM")
  return result
}


export const getObjectsByID = (objectsArr, objectsIDArr) => {
  let arrObjID = objectsIDArr.map((el, idx) => {
    return el.objID
  })
  let objKeys = {}
  objectsArr.forEach((el) => {
    objKeys[el.id] = el.nameObj
  })
  return arrObjID.map((el, idx) => {
    return <option key = {
      idx
    }
    value = {
      objKeys[el]
    }
    />
  })

}

export const getIDObjectByName = (objectName, objectsArr) => {
  let objKeys = {}
  objectsArr.forEach((el) => {
    objKeys[el.nameObj] = el.id
  })
  return objKeys[objectName]
}
export const getObjectByID = (objectID, objectsArr) => {
  let res = ''
  objectsArr.forEach((el) => {
    if (el.id === objectID) {
      res = el.nameObj
    }
  })
  return res
}
export const getObjectColorByID = (objectID, objectsArr) => {
  let res = ''
  objectsArr.forEach((el) => {
    if (el.id === objectID) {
      res = el.color
    }
  })
  return res
}

export const getArrUsersNamesFromObject = (userObj) => {
  let res = userObj.map((el) => {
    return el.name
  })
  return res
}


export const getUserByID = (userArr, userID) => {
  let res = 0
  for (let i = 0; i < userArr.length; i++) {
    if (userArr[i].id === userID) {
      res = userArr[i]
    }
  }
  return res
}
export const getUserByName = (userArr, userName) => {
  let res = 0
  for (let i = 0; i < userArr.length; i++) {
    if (userArr[i].name === userName) {
      res = userArr[i]
    }
  }
  return res
}




export let nameOfdaysWeek = [
  'Понедельник',
  'Вторник',
  'Среда',
  'Четверг',
  'Пятница',
  'Суббота',
  'Воскресенье',
]
export let nameOfdaysWeekFi = [
  'Maanantai',
  'Tiistai',
  'Keskiviikko',
  'Torstai',
  'Perjantai',
  'Lauantai',
  'Sunnuntai',
]

export let excelCells = [
  'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z',
  'AA', 'AB', 'AC', 'AD', 'AE', 'AF', 'AG', 'AH', 'AI', 'AJ', 'AK', 'AL', 'AM', 'AN', 'AO', 'AP', 'AQ', 'AR', 'AS', 'AT', 'AU', 'AV', 'AW', 'AX', 'AY', 'AZ',
  'BA', 'BB', 'BC', 'BD', 'BE', 'BF', 'BG', 'BH', 'BI', 'BJ', 'BK', 'BL', 'BM', 'BN', 'BO', 'BP', 'BQ', 'BR', 'BS', 'BT', 'BU', 'BV', 'BW', 'BX', 'BY', 'BZ',
  'CA', 'CB', 'CC', 'CD', 'CE', 'CF', 'CG', 'CH', 'CI', 'CJ', 'CK', 'CL', 'CM', 'CN', 'CO', 'CP', 'CQ', 'CR', 'CS', 'CT', 'CU', 'CV', 'CW', 'CX', 'CY', 'CZ',
  'DA', 'DB', 'DC', 'DD', 'DE', 'DF', 'DG', 'DH', 'DI', 'DJ', 'DK', 'DL', 'DM', 'DN', 'DO', 'DP', 'DQ', 'DR', 'DS', 'DT', 'DU', 'DV', 'DW', 'DX', 'DY', 'DZ',
  'EA', 'EB', 'EC', 'ED', 'EE', 'EF', 'EG', 'EH', 'EI', 'EJ', 'EK', 'EL', 'EM', 'EN', 'EO', 'EP', 'EQ', 'ER', 'ES', 'ET', 'EU', 'EV', 'EW', 'EX', 'EY', 'EZ',
  'FA', 'FB', 'FC', 'FD', 'FE', 'FF', 'FG', 'FH', 'FI', 'FJ', 'FK', 'FL', 'FM', 'FN', 'FO', 'FP', 'FQ', 'FR', 'FS', 'FT', 'FU', 'FV', 'FW', 'FX', 'FY', 'FZ',
  'GA', 'GB', 'GC', 'GD', 'GE', 'GF', 'GG', 'GH', 'GI', 'GJ', 'GK', 'GL', 'GM', 'GN', 'GO', 'GP', 'GQ', 'GR', 'GS', 'GT', 'GU', 'GV', 'GW', 'GX', 'GY', 'GZ',
  'HA', 'HB', 'HC', 'HD', 'HE', 'HF', 'HG', 'HH', 'HI', 'HJ', 'HK', 'HL', 'HM', 'HN', 'HO', 'HP', 'HQ', 'HR', 'HS', 'HT', 'HU', 'HV', 'HW', 'HX', 'HY', 'HZ',
]

export let spendingReason = [
  'Зарплата',
  'Магазин',
  'Аренда',
  'Электричество',
  'Сантехника',
  'Другое',
]


  


export let getHoursAnding = (number) => {
  let twoLastSymbol = number ? number.toString().slice(-2) : 0
  let lastSymbol = ''

  if (twoLastSymbol === '.5') {
    lastSymbol = number ? number.toString().slice(-3)[0] : 0
  } else {
    lastSymbol = number ? number.toString().slice(-1) : 0
  }

  lastSymbol = +lastSymbol
  if (lastSymbol === 0) return 'часов'
  if (lastSymbol === 1) return 'час'
  if (lastSymbol < 5) return 'часa'
  if (lastSymbol > 9) return 'часов'
  return 'часов'
}
export let getHoursAndingFI = (number) => {
  if (number === 1) return 'tunti'
  return 'tuntia'
}

export const sortHoursbyWeeks = (hours) => {
  return hours.reduce((accumulator, currentValue) => { //sort array by weekNumber
    const {
      weekNumber
    } = currentValue;
    if (!accumulator[weekNumber]) {
      accumulator[weekNumber] = [];
    }
    accumulator[weekNumber].push(currentValue);
    return accumulator;
  }, {});
}
export const isAdmin = (userName) => {
  let arr = [
    'Denis',
    'Olga',
    'Ants',
    'Rait'
  ]
  return arr.includes(userName)
}

export const sortWeekByDay = (arr) => {
  let sortArr = [
    [],
    [],
    [],
    [],
    [],
    [],
    [],
  ]
  arr.forEach(element => {
    sortArr[element.dayOfWeek].push(element)
  });

  return sortArr
}
export const getTotalHoursForWeek = (arr) => {
  let result = 0
  arr.forEach(element => {
    result += +element.time
  });
  return result
}

export const setLocalStoreNotice = (type, data) => {
  type === 'GOODS' ?
    localStorage.setItem('goodsVkrID', data.serverGoodsID) :
    localStorage.setItem('tasksVkrID', data.serverTasksID)
}

export const getCodeOfObjectByName = (arr, name)  =>{
  for (let i = 0; i < arr.length; i++) {
      if (arr[i].nameObj === name) {
       return  arr[i].code
        ? arr[i].code
        : null
      }
  }
  return null; // Возвращаем null, если объект не найден
}

export let WORDS = {
  hours:{FI:'Tunnit',RU:'Часы'},
  report:{FI:'Raportti',RU:'Отчет'},
  objects:{FI:'Työmaat',RU:'Объекты'},
  filter:{FI:'Suodattaa',RU:'Фильтр'},
  today:{FI:'Tänään',RU:'Сегодня'},
  week:{FI:'Viikko',RU:'Неделя'},
  year:{FI:'Vuosi',RU:'Год'},
  total:{FI:'Yhteensä',RU:'Всего'},
  countOfHours:{FI:'Tuntien lukumäärä',RU:'Количество часов'},
  kindOfJob:{FI:'Työn tyyppi',RU:'Вид работы'},
  object:{FI:'Työmaa',RU:'Объект'},
  add:{FI:'Lisätä',RU:'Добавить'},
  profile:{FI:'Profiili',RU:'Профиль'},
  from:{FI:'Alkaen',RU:'От'},
  to:{FI:'Ennen',RU:'До'},
  worker:{FI:'Työntekijä',RU:'Работник'},
  workers:{FI:'Työntekijät',RU:'Работники'},
  filtering:{FI:'Suodattaa',RU:'Фильтровать'},
  all:{FI:'Kaikki',RU:'Все'},
  reportOfWeek:{FI:'Viikkoraportti',RU:'Отчет за неде'},
  nameOfObject:{FI:'Objektin nimi',RU:'Имя объекта'},
  nuumberOfObject:{FI:'Objektin numero',RU:'Номер объекта'},
  adresOfObject:{FI:'Objektin osoite',RU:'Адрес объекта'},
  name:{FI:'Nimi',RU:'Имя'},
  surname:{FI:'Sukunimi',RU:'Фамилия'},
  phone:{FI:'Puhelin',RU:'Телефон'},
  pass:{FI:'Salasana',RU:'Пароль'},
  shirt:{FI:'T-paita',RU:'Футболка'},
  pants:{FI:'Housut',RU:'Штаны'},
  shose:{FI:'Kengät',RU:'Обувь'},
  save:{FI:'Tallenna',RU:'Сохранить'},
  tasks:{FI:'Tehtävät',RU:'Задачи'},
  code:{FI:'Työkoodi',RU:'Код работы'},
  workCodes:{FI:'Työkoodit',RU:'Коды работ'},
  
}