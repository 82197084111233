import { useState } from 'react';
import cls from './List.module.css'
import ObjectsBar from '../ObjectsBar/ObjectsBar';
import UsersBar from '../UsersBar/UsersBar';
import ExcelJS from "exceljs";
import { WORDS, getCurrentWeek, getCurrentYear, isAdmin } from '../../../assets/functions';
import PORT from '../../../api/config';
import moment from 'moment';

function List(props) {
  const [weekNumber, setWeekNumber] = useState(getCurrentWeek())
  const [isObjectsActive, setIsObjectsActive] = useState(true)

  let admin = isAdmin(props.app.userName)

 

  const clickOnReportButton = (data) => {
    fetch(PORT + 'report/' + weekNumber  + '/' + getCurrentYear(), {
      method: "GET",
      headers: new Headers({
        Accept: 'application/json',
        'Content-Type': 'application/json'
      })
    })
      .catch((err) => {
        console.log(err)
      })
      .then((response) => {
        return response.text()
      })
      .then((data) => {
        let hoursArr = JSON.parse(data)
        let sortObj = {}
        for (let key in hoursArr) {
          let hourObjectID = hoursArr[key].objID
          let userName = hoursArr[key].userName
          if (!sortObj[hourObjectID]) {
            sortObj[hourObjectID] = {}
            let usersObj = sortObj[hourObjectID]
            usersObj[userName] = [hoursArr[key]]
          } else {
            let usersObj = sortObj[hourObjectID]
            if (!usersObj[userName]) {
              usersObj[userName] = []
            }
            usersObj[userName].push(hoursArr[key])
          }
        }

        const workbook = new ExcelJS.Workbook();
        let nameExcel = `week ${weekNumber}`
        workbook.addWorksheet(nameExcel);
        const worksheet = workbook.getWorksheet(nameExcel);
        let time = moment().isoWeek(+weekNumber).isoWeekday(1)
        worksheet.columns = [
          { header: '', key: '', width: 35 },
          { header: `${time.format("D.MM")}`, key: 0, width: 10 },
          { header: `${time.add(1, 'day').format("D.MM")}`, key: 1, width: 10 },
          { header: `${time.add(1, 'day').format("D.MM")}`, key: 2, width: 10 },
          { header: `${time.add(1, 'day').format("D.MM")}`, key: 3, width: 10 },
          { header: `${time.add(1, 'day').format("D.MM")}`, key: 4, width: 10 },
          { header: `${time.add(1, 'day').format("D.MM")}`, key: 5, width: 10 },
          { header: `${time.add(1, 'day').format("D.MM")}`, key: 6, width: 10 },
          { header: `Yhteensä`, key: 7, width: 10 },
        ];
        let HOURS_FOR_WEEK = 0 //sum of all hours by week
        for (let key in sortObj) {
          let object = sortObj[key]
          let nameObj = ''
          let objects = props.objects.objects
          let totalHoursForObj = 0 //sum of one object hours by week
          for (let i = 0; i < objects.length; i++) {
            if (objects[i].id == key) {
              nameObj = `${objects[i].nameObj} - ${objects[i].number}`
            }
          }
          if (nameObj === '') {
            nameObj = 'нет объекта'
          }



          const rowNameObj = []
          rowNameObj[0] = nameObj
          worksheet.getRow(1).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '999999' }
          };
          worksheet.addRow(rowNameObj).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'bcbcbc' }
          };
          worksheet.getColumn(9).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '6aa84f' }
          };

          for (let key in object) {
            let user = object[key]
            const rowNameUser = []
            rowNameUser[0] = key
            rowNameUser[1] = 0
            rowNameUser[2] = 0
            rowNameUser[3] = 0
            rowNameUser[4] = 0
            rowNameUser[5] = 0
            rowNameUser[6] = 0
            rowNameUser[7] = 0
            rowNameUser[8] = 0
            for (let i = 0; i < user.length; i++) {
              let hour = user[i]
              rowNameUser[+hour.dayOfWeek + 1] += +hour.time
              rowNameUser[8] += +hour.time
              totalHoursForObj += +hour.time
              HOURS_FOR_WEEK += +hour.time

            }

            worksheet.addRow(rowNameUser);
          }
          const rowSpace = []
          rowNameObj[0] = ''
          rowSpace[9] = totalHoursForObj

          worksheet.addRow(rowSpace);
        }
        const rowTotalHoursOfObjects = [] //add row of sum of all hours by week
        rowTotalHoursOfObjects[9] = HOURS_FOR_WEEK
        worksheet.addRow(rowTotalHoursOfObjects);

        return workbook.xlsx.writeBuffer()
      })
      .then((uint8Array) => {
        const blob = new Blob([uint8Array], { type: "application/octet-binary" });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `week_${weekNumber}.xlsx`;
        a.click();
        a.remove();
      })
  }



  return (
    <div className={cls.wrapper}>
      {admin
        ? <div className={cls.reportWrapper}>
          <button className={cls.reportButton} onClick={clickOnReportButton}>{WORDS.reportOfWeek[props.app.lang]}</button>
          <input value={weekNumber} className={cls.inputReport} onChange={(e) => { setWeekNumber(+e.currentTarget.value) }} type="number" />
        </div>
        : null}
      <div className={cls.header}>
        <div onClick={() => { setIsObjectsActive(true) }} className={`${cls.button} ${isObjectsActive ? cls.butonActive : null}`}>{WORDS.objects[props.app.lang]}</div>
        {admin
          ? <div onClick={() => { setIsObjectsActive(false) }} className={`${cls.button} ${isObjectsActive ? null : cls.butonActive}`}>{WORDS.workers[props.app.lang]}</div>
          : null}

      </div>

      {isObjectsActive
        ? <ObjectsBar
          app={props.app}
          addSalaryInObjectSpending={props.addSalaryInObjectSpending}
          addNewObject={props.addNewObject}
          archiveObject={props.archiveObject}
          objects={props.objects}
          changeAppState={props.changeAppState}
          setCurrentObject={props.setCurrentObject}
        />
        : <UsersBar
          app={props.app}
          initCurrentUser={props.initCurrentUser}
          users={props.users}
          addNewUser={props.addNewUser}
          deleteUser={props.deleteUser}
          changeAppState={props.changeAppState}
        />}
    </div>
  );
}

export default List;
